import ApplicationController from "./application_controller";

// Connects to data-controller="otp"
export default class extends ApplicationController {
  static targets = ["input", "form"];

  connect() {
    this.focusFirstEmpty()
  }

  handleChange(e) {
    const allFilled = this.inputTargets.every((input) => input.value.trim().length === 1);

    if (allFilled) {
      this.formTarget.requestSubmit();
    }
    let current_index = e.target.id.split('_')[1]
    if(parseInt(current_index, 10) < 5) {
      let next_input = document.getElementById(`otp_${parseInt(current_index, 10) + 1}`)
      next_input.focus();
      next_input.setSelectionRange(0, 0);
    } else {
      this.focusFirstEmpty()
    }
  }

  handleKeyDown(e) {
    const currentIndex = parseInt(e.target.id.split('_')[1], 10);

    if (e.key === "Backspace") {
      if (e.target.value.trim() === "" && currentIndex > 0) {
        const previousInput = document.getElementById(`otp_${currentIndex - 1}`);
        previousInput.focus();
        previousInput.value = "";
        previousInput.setSelectionRange(0, 0);
        e.preventDefault();
      }
    }
  }

  focusFirstEmpty() {
    this.inputTargets.some(input => {
      if (!input.value.trim()) {
        input.focus();
        input.setSelectionRange(0, 0);
        return true;
      }

      return false;
    });
  }
}
